// Packages
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';


// Components
import Container from 'components/Container'
import EventNavigation from 'components/EventNavigation'
import Avatar from 'components/Avatar'




// Assets
import './assets/styles.css'
import logo from './assets/logo.svg'


const Header = props => {

    // Props
    const { nav } = props


    /*
    Import variables from the Auth0 context
    */
    const {
        user
    } = useAuth0();








    return (
        <header className="Header">

            <Container>
                <div className="Header__body">
                    <img src={logo} className="Header__logo" alt="Tropic Skincare"/>
                    { nav &&
                        <EventNavigation items={nav}/>
                    }
                </div>
                <div  className="Header__footer">
                    <Link to="/account" className="Header__account">Your Account</Link>

                    <Avatar firstname={user.given_name} lastname={user.family_name} picture={user['https://id.tropicskincare.com/picture_url']} showName={false}/>
                </div>
            </Container>

        </header>
    );
}


export default Header;
