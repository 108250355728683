


const getUserInitials = (firstname, lastname) => {

    let initials = '';

    if ( firstname ) {
        initials += firstname.substring(0, 1);
    }

    if ( lastname ) {
        initials += lastname.substring(0, 1);
    }

    return initials.trim().toUpperCase();
}



const userHasRole = (roleNames, userRoles) => {

    for ( var i=0; i<userRoles.length; i++ )
    {
        for ( var j=0; j<roleNames.length; j++ )
        {
            if ( roleNames[j] === userRoles[i] )
            {
                return true;
            }
        }
    }

    return false;
}





export {
    userHasRole,
    getUserInitials
};
