// Packages
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'


// Components
import CheckboxList from 'components/CheckboxList'


// Assets
import './assets/styles.css'


const AttendeeForm = props => {

    // Props
    const {
        onChange,
        defaultName,
        defaultDiet,
        defaultAllergies,
        defaultAccessibility,
        fixedName } = props


    // State
    const [name, setName] = useState('')


    const [diet, setDiet] = useState([])
    const [allergies, setAllergies] = useState('')
    const [accessibility, setAccessibility] = useState('')


    useEffect(() => {
        setName(defaultName)
        setDiet(defaultDiet)
        setAllergies(defaultAllergies)
        setAccessibility(defaultAccessibility)
    }, [defaultName, defaultDiet, defaultAllergies, defaultAccessibility])



    useEffect(() => {

        onChange({
            attendee_name: name,
            dietary_requirements: diet,
            allergies,
            accessibility_requirements: accessibility
        })
    // eslint-disable-next-line
    }, [name, diet, allergies, accessibility])


    const DIET_OPTIONS = [
        'No Dietary Requirements',
        'Vegetarian',
        'Vegan',
        'Gluten Free',
        'Dairy Free'
    ]


    return (
        <div className="AttendeeForm">

            <div className="form__field">
                <label className="form__field__label">Attendee Name <span className="form__field__label__required">Required</span></label>
                <input disabled={fixedName} onChange={(e) => {
                    setName(e.target.value)
                }} value={name} className="form__field__input" placeholder="Enter attendee name"/>
            </div>



            <div className="form__field">
                <label className="form__field__label">Dietary Requirements <span className="form__field__label__required">Required</span></label>
                <CheckboxList showOther={true} values={diet??[]} onChange={(values) => {
                    setDiet(values)
                }} items={DIET_OPTIONS}/>
            </div>


            <div className="form__field">
                <label className="form__field__label">Allergy Information</label>
                <textarea onChange={(e) => {
                    setAllergies(e.target.value)
                }} value={allergies} className="form__field__input" placeholder="Enter allery information"/>
            </div>


            <div className="form__field">
                <label className="form__field__label">Accessibility Requirements</label>
                <textarea onChange={(e) => {
                    setAccessibility(e.target.value)
                }} value={accessibility} className="form__field__input" placeholder="Enter accessibility information"/>
            </div>

        </div>
    )
}


AttendeeForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    defaultName: PropTypes.string,
    defaultDiet: PropTypes.array,
    fixedName: PropTypes.bool
}

export default AttendeeForm
