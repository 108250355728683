// Packages
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'


// Components
import Container from 'components/Container'
import SectionTitle from 'components/SectionTitle'
import Button from 'components/Button'
import Loading from 'components/Loading'
import BasketExpiryTimer from 'components/BasketExpiryTimer'


// Other
import Api from 'other/Api'


// Assets
import './assets/styles.css'


const AmbassadorScreen = () => {

    const { checkoutId } = useParams()


    // State
    const [ checkout, setCheckout ] = useState()
    const [ checkoutLoading, setCheckoutLoading ] = useState(true)


    useEffect(() => {

        Api.checkouts.get(checkoutId, {
            success: (response) => {
                setCheckout(response.data);
            },
            error: (error) => {
                alert('error');
            },
            after: () => {
                setCheckoutLoading(false)
            }
        })

    }, [checkoutId])


    if ( checkoutLoading )
    {
        return (
            <Container width="narrow">
                <Loading align="center" message="Loading basket"/>
            </Container>
        );
    }


	return (
		<div className="AmbassadorScreen">



            <Container width="narrow">

                <BasketExpiryTimer expiry={checkout.expires_at}/>

                <SectionTitle title="Check Your Details"/>


                <div className="AmbassadorScreen__line">
                    <div className="AmbassadorScreen__line__header">
                        Ambassador #
                    </div>
                    <div className="AmbassadorScreen__line__body">
                        <b>{checkout.ambassador.id}</b>
                    </div>
                </div>


                <div className="AmbassadorScreen__line">
                    <div className="AmbassadorScreen__line__header">
                        Name
                    </div>
                    <div className="AmbassadorScreen__line__body">
                        <b>{checkout.ambassador.firstname} {checkout.ambassador.lastname}</b>
                    </div>
                </div>


                <div className="AmbassadorScreen__line">
                    <div className="AmbassadorScreen__line__header">
                        Email
                    </div>
                    <div className="AmbassadorScreen__line__body">
                        <b>{checkout.ambassador.email}</b>
                    </div>
                </div>

                <div className="AmbassadorScreen__line">
                    <div className="AmbassadorScreen__line__header">
                        Telephone
                    </div>
                    <div className="AmbassadorScreen__line__body">
                        <b>X</b>
                    </div>
                </div>


                <div style={{marginTop: '50px', textAlign: 'center'}}><Button link={`/checkout/${checkoutId}/summary`} colour="red" label="Continue >"/></div>

            </Container>

        </div>
	)
}

export default AmbassadorScreen
