// Packages
import PropTypes from 'prop-types';


// Components
import UserInitials from 'components/UserInitials';


// Assets
import './assets/styles.css';


const Avatar = props => 
{
    // Props
    let { picture, firstname, lastname, size, showName } = props;


    const styles = {};

    if ( null !== picture && '' !== picture )
    {
        styles.backgroundImage = "url('" + picture + "')";
    }


    return (
        <div className="Avatar">

            { firstname && lastname && showName === true &&
            <div className="Avatar__name">{firstname} {lastname}</div>
            }

            <div className={`Avatar__image Avatar__image--size-${size}`} style={styles}>

            { ! picture &&
                <UserInitials firstname={firstname} lastname={lastname}/>
            }

            </div>

        </div>
    );
}


// Prop Types
Avatar.propTypes = {
    picture: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    showName: PropTypes.bool
};


// Default Props
Avatar.defaultProps = {
    size: 'default',
    showName: true
};

export default Avatar;
