// Packages
import { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom'



// Components
import Container from 'components/Container';
import SectionTitle from 'components/SectionTitle'
import Button from 'components/Button';
import Loading from 'components/Loading'
import CouponForm from 'components/CouponForm'
import BasketExpiryTimer from 'components/BasketExpiryTimer'


// Other
import Api from 'other/Api'


// Assets
import './assets/styles.css'


const SummaryScreen = () => {


    const { checkoutId } = useParams()


    // State
    const [ checkout, setCheckout ] = useState()
    const [ checkoutLoading, setCheckoutLoading ] = useState(true)

    const [ applyingCoupon, setApplyingCoupon] = useState(false)
    const [ couponError, setCouponError ] = useState()


    const loadCheckout = useCallback(() => {
        //setCheckoutLoading(true)

        Api.checkouts.get(checkoutId, {
            success: (response) => {
                setCheckout(response.data);
            },
            error: (error) => {
                alert('error');
            },
            after: () => {
                setCheckoutLoading(false)
            }
        })
    }, [checkoutId])



    useEffect(() => {

        loadCheckout()

    }, [loadCheckout])


    const applyCoupon = (code) => {
        setApplyingCoupon(true)

        Api.checkouts.applyCoupon(checkoutId, {
            data: {
                code
            },
            success: (response) => {
                loadCheckout()
            },
            error: (error) => {
                setCouponError(error.error)
            },
            // before: () => {
            //     setApplyingCoupon(true)
            // },
            after: () => {
                setApplyingCoupon(false)
            }
        })

    }


    if ( checkoutLoading )
    {
        return (
            <Container width="narrow">
                <Loading align="center" message="Loading basket"/>
            </Container>
        );
    }









	return (
		<>



            <Container width="narrow">

                <BasketExpiryTimer expiry={checkout.expires_at}/>

                <SectionTitle title="Check Your Order"/>


                <div className="SummaryScreen__ambassador">
                    <div className="SummaryScreen__ambassador__header">
                        Ambassador
                    </div>
                    <div className="SummaryScreen__ambassador__body">
                        <b>#{checkout.ambassador.id}<br/></b>
                        <b>{checkout.ambassador.firstname} {checkout.ambassador.lastname}</b><br/>
                        <b>{checkout.ambassador.email}</b><br/>
                    </div>
                </div>




                <div className="SummaryScreen__tickets">


                {checkout.tickets.map((ticket, index) => {

                    return (

                        <div key={index} className="SummaryScreen__tickets__item">
                            <div className="SummaryScreen__tickets__item__number">
                                #{index+1}
                            </div>
                            <div className="SummaryScreen__tickets__item__name">
                                <b>{ticket.event.name}</b><br/>
                                {ticket.ticket_type.name}<br/>
                                <div className="SummaryScreen__tickets__item__attendeeName">{ticket.attendee_name}</div>
                            </div>
                            <div className="SummaryScreen__tickets__item__price">
                                £ {Number(ticket.ticket_type.price).toFixed(2)}
                            </div>

                        </div>
                    )

                })}

                </div>


                <div className="SummaryScreen__summary">

                    <div>

                        { ! checkout.coupon &&
                            <CouponForm error={couponError} onApply={(code) => {
                            applyCoupon(code)
                            }} loading={applyingCoupon}/>
                        }


                    </div>

                    <div className="SummaryScreen__summary__totals">

                        {checkout.coupon &&
                            <>
                                Sub Total: <b style={{marginLeft: '50px'}}>£ {Number(checkout.sub_total).toFixed(2)}</b><br/>
                                Discount ({checkout.coupon.code}): <b style={{marginLeft: '50px'}}>- £ {Number(checkout.discount).toFixed(2)}</b><br/>
                            </>
                        }

                        Total: <b style={{marginLeft: '50px'}}>£ {Number(checkout.total).toFixed(2)}</b>
                    </div>

                </div>



                <div style={{marginTop: '50px', textAlign: 'center'}}><Button link={`/checkout/${checkoutId}/payment`} colour="red" label="Continue To Payment >"/></div>

            </Container>

        </>
	)
}

export default SummaryScreen
