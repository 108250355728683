// Packages
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'


// Assets
import './assets/styles.css'


const TicketQuantityControl = props => {

    // Props
    const { onChange, max } = props


    // State
    const [quantity, setQuantity] = useState(0)
    const [canDecrease, setCanDecrease] = useState(true)
    const [canIncrease, setCanIncrease] = useState(true)


    useEffect(() => {

        setCanDecrease(quantity>0);

        if ( max )
        {
            setCanIncrease(quantity<max);
        }


    }, [quantity, max])

    const changeQuantity = (value) => {

        if ( value === 1 && ! canIncrease )
        {
            return;
        }

        if ( value === -1 && ! canDecrease )
        {
            return;
        }

        setQuantity(quantity+value);
        onChange(quantity+value);
    }


    return (
        <div className="TicketQuantityControl">

            <div onClick={() => {
                changeQuantity(-1);
            }} className={`TicketQuantityControl__change TicketQuantityControl__change--minus ${canDecrease ? '' : 'TicketQuantityControl__change--disabled'}`}></div>

            <div className="TicketQuantityControl__quantity">{quantity}</div>

            <div onClick={() => {
                changeQuantity(1);
            }} className={`TicketQuantityControl__change TicketQuantityControl__change--plus ${canIncrease ? '' : 'TicketQuantityControl__change--disabled'}`}></div>

        </div>
    );
}


TicketQuantityControl.propTypes = {
    onChange: PropTypes.func.isRequired,
    max: PropTypes.number
}

export default TicketQuantityControl;
