// Components
import Container from 'components/Container'
import SectionTitle from 'components/SectionTitle'


import checkIcon from 'assets/check.svg'

const CompleteScreen = () => {




	return (
		<>
            <Container width="narrow">

                <div style={{textAlign: 'center', marginBottom: '20px'}}>
                    <img src={checkIcon} alt="Complete"/>
                </div>

                <SectionTitle title="Order Complete"/>
                <br/><br/>
                <p style={{textAlign: 'center'}}>Your order is complete and we have sent you confirmation via email.</p>

            </Container>

        </>
	)
}

export default CompleteScreen
