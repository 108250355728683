// Components
import PropTypes from 'prop-types'
import Container from 'components/Container'
import SectionTitle from 'components/SectionTitle'
import AccommodationItem from './Item'


// Assets
import './assets/styles.css'


const AccommodationSection = props => {

    // Props
    const { items } = props;


    return (
        <div id="hotels" className="AccommodationSection">
            <Container>
                <SectionTitle icon="accommodation" title="Accommodation"/>

                <div className="AccommodationSection__items">

                {items.map((item) => {
                    return (
                        <AccommodationItem key={item.id} {...item}/>
                    )
                })}

                </div>
            </Container>
        </div>
    )
}

AccommodationSection.propTypes = {
    items: PropTypes.array.isRequired
}

export default AccommodationSection
