// Packages
import PropTypes from 'prop-types';


// Components
import Container from 'components/Container';
import SectionTitle from 'components/SectionTitle'


// Assets
import './assets/styles.css';


const VenueSection = props => {

    // Props
    const { address } = props;

    return (
        <section id="venue" className="VenueSection">

            <Container>

                <SectionTitle icon="venue" title="Venue"/>

                { address &&
                    <div className="VenueSection__address">
                        {address}
                    </div>
                }


                <iframe title="Google Map Of Venue" className="VenueSection__map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2431.3746147642382!2d-1.7192091479143945!3d52.45424267970253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4870b118ec847271%3A0x2709d3396706d7df!2sNational%20Exhibition%20Centre!5e0!3m2!1sen!2suk!4v1655656324941!5m2!1sen!2suk" width="600" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                <div className="VenueSection__clickformap">
                    <a href={`https://www.google.co.uk/maps/search/${address}`} target="_blank" rel="noreferrer">Click to visit Google Maps</a>
                </div>


            </Container>

        </section>
    );
}


VenueSection.propTypes = {
    address: PropTypes.string
}

export default VenueSection;
