
// Components
import Header from 'components/Header'
import Footer from 'components/Footer'


const EventLayout = props => {

     const NAV_ITEMS = [
        { label: 'Introduction', anchor: 'introduction'},
        { label: 'Tickets', anchor: 'tickets'},
        { label: 'Hotels', anchor: 'hotels'},
        { label: 'Venue', anchor: 'venue'},
        { label: 'FAQs', anchor: 'faqs'}
    ]

    return (
        <div className="DefaultLayout">
            <div className="DefaultLayout__header">
                <Header nav={NAV_ITEMS}/>
            </div>
            <div className="DefaultLayout__body">
                {props.children}
            </div>
            <div className="DefaultLayout__footer">
                <Footer/>
            </div>
        </div>
    )
}


export default EventLayout
