// Packages
import axios from 'axios';


// Other
import Config from 'other/Config';


/** 
 * Adds defaults to all requests
 */
const setDefaults = (args) => {

    const defaults = {
        // Triggered before request
        before: () => {},
        // Triggered after request (success or error)
        after: () => {},
        // Triggered on a successful request
        success: () => { },
        // If the success has a redirect, use this
        successRedirect: () => {},
        // Triggered on an error
        error: (error) => { alert('An unhandled error occurred: ' + error.message); },
        // Headers
        headers: {},
        // GET parameters
        params: {},
        // POST body data
        data: {},
        // Response type
        responseType: 'json',
        // Bearer token
        bearerToken: true
    };

    for ( const name in defaults )
    {
        if ( ! args[name] )
        {
            args[name] = defaults[name]
        }
    }

    return args;
}




/**
 * The code that actually makes the API calls
 */
const apiRequest = async(method, url, request) => {

    const params = setDefaults(request);

    const token = await getAccessToken();

    params.before();

    if ( url.substring(0, 4) !== 'http' )
    {
        url = Config.API_URL + url;
    }

    if ( ! params.noAuth )
    {
        params.headers.Authorization = 'Bearer ' + token;
    }


    try 
    {
        const response = await axios.request({
            url: url,
            method: method.toLowerCase(),
            params: params.params,
            data: params.data,
            responseType: params.responseType,
            headers: params.headers
        });

        params.success(response.data);
        params.after(response.data);
        params.successRedirect(response.data);
    }
    catch ( exception )
    {
        if ( exception.response !== undefined ) { 

            params.error(exception.response.data);
        }

        params.after();
    }
}




/**	
 * Return the access token from local storage
 */
const getAccessToken = async () => {

	return sessionStorage.getItem('access-token');
}


/**
 * Events
 * ----------------------------------------------
 */
const listEvents = async (request) => {
    apiRequest('get', '/events', request);
}

const getEvent = async (id, request) => {
    apiRequest('get', '/events/' + id, request);
}

const createEvent = async (request) => {
    apiRequest('post', '/events', request);
}

const updateEvent = async (id, request) => {
    apiRequest('put', '/events/' + id, request);
}

const deleteEvent = async (id, request) => {
    apiRequest('delete', '/events/' + id, request);
}


/**
 * Ticket Types
 * ----------------------------------------------
 */
const listTicketTypes = async (request) => {
    apiRequest('get', '/tickettypes', request);
}

const getTicketType = async (id, request) => {
    apiRequest('get', '/tickettypes/' + id, request);
}

const createTicketType = async (request) => {
    apiRequest('post', '/tickettypes', request);
}

const updateTicketType = async (id, request) => {
    apiRequest('put', '/tickettypes/' + id, request);
}

const deleteTicketType = async (id, request) => {
    apiRequest('delete', '/tickettypes/' + id, request);
}


/**
 * Hotels
 * ----------------------------------------------
 */
const listHotels = async (request) => {
    apiRequest('get', '/hotels', request);
}

const getHotel = async (id, request) => {
    apiRequest('get', '/hotels/' + id, request);
}

const createHotel = async (request) => {
    apiRequest('post', '/hotels', request);
}

const updateHotel = async (id, request) => {
    apiRequest('put', '/hotels/' + id, request);
}

const deleteHotel = async (id, request) => {
    apiRequest('delete', '/hotels/' + id, request);
}


/**
 * FAQs
 * ----------------------------------------------
 */
const listFaqs = async (request) => {
    apiRequest('get', '/faqs', request);
}

const getFaq = async (id, request) => {
    apiRequest('get', '/faqs/' + id, request);
}

const createFaq = async (request) => {
    apiRequest('post', '/faqs', request);
}

const updateFaq = async (id, request) => {
    apiRequest('put', '/faqs/' + id, request);
}

const deleteFaq = async (id, request) => {
    apiRequest('delete', '/faqs/' + id, request);
}


/**
 * Checkout
 * ----------------------------------------------
 */
const createCheckout = async (request) => {
    apiRequest('post', '/checkouts', request);
}

const getCheckout = async (id, request) => {
    apiRequest('get', '/checkouts/' + id, request);
}

const updateCheckout = async (id, request) => {
    apiRequest('put', '/checkouts/' + id, request);
}

const getCheckoutPaymentIntent = async (id, request) => {
    apiRequest('post', '/checkouts/' + id + '/payment-intent', request);
}

const applyCheckoutCoupon = async (id, request) => {
    apiRequest('post', '/checkouts/' + id + '/apply-coupon', request);
}

const approveCheckout = async (id, request) => {
    apiRequest('post', '/checkouts/' + id + '/approve', request);
}



/**
 * Auth0 User's endpoints
 * ----------------------------------------------
 */
const listUserOrders = async (request) => {
    apiRequest('get', '/user/orders', request);
}

const getUserOrder = async (id, request) => {
    apiRequest('get', '/user/orders/' + id, request);
}



const output = {
    events: {
        list: listEvents,
        get: getEvent,
        create: createEvent,
        update: updateEvent,
        delete: deleteEvent
    },
    ticketTypes: {
        list: listTicketTypes,
        get: getTicketType,
        create: createTicketType,
        update: updateTicketType,
        delete: deleteTicketType
    },
    hotels: {
        list: listHotels,
        get: getHotel,
        create: createHotel,
        update: updateHotel,
        delete: deleteHotel
    },
    faqs: {
        list: listFaqs,
        get: getFaq,
        create: createFaq,
        update: updateFaq,
        delete: deleteFaq
    },
    checkouts: {
        get: getCheckout,
        create: createCheckout,
        update: updateCheckout,
        getPaymentIntent: getCheckoutPaymentIntent,
        applyCoupon: applyCheckoutCoupon,
        approve: approveCheckout
    },
    user: {
        listOrders: listUserOrders,
        getOrder: getUserOrder
    }
};

export default output;
