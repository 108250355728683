// Packages
import { useState } from 'react';


// Assets
import './assets/styles.css';


const FaqItem = props => {

    // Props
    const { question, answer } = props;


    // State
    const [ open, setOpen ] = useState(false);


    return (
        <div className="FaqItem">

            <div onClick={() => {
                setOpen(!open);
            }} className={`FaqItem__question ${open ? 'FaqItem__question--open' : ''}`}>{question}</div>

            { open &&
                <div className="FaqItem__answer">{answer}</div>
            }

        </div>

    );
}

export default FaqItem;
