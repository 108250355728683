// Packages
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

// Components
import Container from 'components/Container'
import SectionTitle from 'components/SectionTitle'
import Loading from 'components/Loading'


// Other
import Api from 'other/Api'


// Assets
import './assets/styles.css'


const AccountOrdersScreen = () => {


    // State
    const [ orders, setOrders ] = useState()
    const [ ordersLoading, setOrdersLoading ] = useState(true)


    useEffect(() => {

        Api.user.listOrders({
            success: (response) => {
                setOrders(response.data)
            },
            error: (error) => {
                alert('error')
            },
            after: () => {
                setOrdersLoading(false)
            }
        })


    })


    if ( ordersLoading )
    {
        return  <Container width="narrow">
                    <Loading message="Loading orders"/>
                </Container>
    }

	return (
		<>
            <Container width="narrow">
                <SectionTitle title="Your Orders"/>

                {orders.map((item) => {
                    return (
                        <Link key={item.id} className="AccountOrdersScreen__item" to={`/account/orders/${item.id}`}>
                            <div className="AccountOrdersScreen__item__date">
                                {dayjs(item.created_at).format('D MMMM YYYY HH:MM')}
                            </div>
                            <div className="AccountOrdersScreen__item__total">
                                £ {Number(item.total).toFixed(2)}
                            </div>
                        </Link>
                    )
                })}

            </Container>
        </>
	)
}

export default AccountOrdersScreen
