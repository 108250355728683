// Packages
import PropTypes from 'prop-types';


// Assets
import './assets/styles.css';


const Container = props => {

    // Props
    const { width } = props;

    
    return (
        <div className={`Container Container--width-${width}`}>
            {props.children}
        </div>
    );
}


Container.propTypes = {
    width: PropTypes.string
}

Container.defaultProps = {
    width: 'default'
}

export default Container;
