// Libraries
import { Routes, Route } from 'react-router-dom'


// Screens
import HomeScreen from 'screens/Account/Home'
import OrdersScreen from 'screens/Account/Orders'
import OrderViewScreen from 'screens/Account/Orders/View'


// Layouts
import DefaultLayout from 'layouts/Default'


const AccountRoutes = () =>
{
	return (
		<DefaultLayout>
			<Routes>
                <Route exact path="/" element={<HomeScreen/>}/>
                <Route exact path="/orders" element={<OrdersScreen/>}/>
                <Route exact path="/orders/:orderId" element={<OrderViewScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default AccountRoutes
