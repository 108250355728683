// Libraries
import { Routes, Route } from 'react-router-dom'


// Screens
import AttendeesScreen from 'screens/Checkout/Attendees'
import AmbassadorScreen from 'screens/Checkout/Ambassador'
import SummaryScreen from 'screens/Checkout/Summary'
import PaymentScreen from 'screens/Checkout/Payment'
import CompleteScreen from 'screens/Checkout/Complete'
import ProcessScreen from 'screens/Checkout/Process'


// Layouts
import DefaultLayout from 'layouts/Default'


const CheckoutRoutes = () =>
{

    return (
        <DefaultLayout>
            <Routes>
                <Route exact path="/:checkoutId" element={<AttendeesScreen/>}/>
                <Route exact path="/:checkoutId/ambassador" element={<AmbassadorScreen/>}/>
                <Route exact path="/:checkoutId/summary" element={<SummaryScreen/>}/>
                <Route exact path="/:checkoutId/payment" element={<PaymentScreen/>}/>
                <Route exact path="/:checkoutId/process" element={<ProcessScreen/>}/>
                <Route exact path="/complete" element={<CompleteScreen/>}/>
            </Routes>
        </DefaultLayout>
    )
}


export default CheckoutRoutes
