// Packages
import { Routes, Route } from "react-router-dom"


// Routes
import EventRoutes from 'routes/events';
import CheckoutRoutes from 'routes/checkout';
import AccountRoutes from 'routes/account';

import MiscRoutes from 'routes/misc';


const MainRoutes = () => 
{
	return (
		<Routes>
			<Route path="/events/*" element={<EventRoutes/>}/>
            <Route path="/checkout/*" element={<CheckoutRoutes/>}/>
            <Route path="/account/*" element={<AccountRoutes/>}/>
            <Route path="/*" element={<MiscRoutes/>}/>
		</Routes>
	);
}


export default MainRoutes;
