// Packages
import PropTypes from 'prop-types'
import { useState } from 'react'


// Components
import Button from 'components/Button'


// Assets
import './assets/styles.css'


const CouponForm = props => {

    // Props
    const { onApply, loading, error } = props


    // State
    const [open, setOpen] = useState(false)
    const [code, setCode] = useState()


    const apply = async () => {
        onApply(code)
    }


    if ( open )
    {
        return (
            <div className="CouponForm">
                <form>
                    <input onChange={(e) => {
                        setCode(e.target.value)
                    }} value={code} className="form__field__input form__field__input--short" placeholder="Enter coupon code"/>
                    <Button loading={loading} onClick={apply} colour="red" size="small" label="Apply"/>
                </form>

                {error &&
                   <div className="CouponForm__error">{error}</div>
                }
            </div>
        )
    }
    else
    {
        return (
            <div onClick={() => {
                setOpen(!open)
            }} className="CouponForm">
                <div className="CouponForm__handle">
                    Apply a coupon code
                </div>
            </div>
        )
    }
}

CouponForm.propTypes = {
    onApply: PropTypes.func.isRequired,
    loading: PropTypes.bool
}

CouponForm.defaultProps = {
    loading: false
}

export default CouponForm
