
// Assets
import './assets/styles.css';


const FullScreenLoading = () => {

    return (
        <div className="FullScreenLoading"/>
    );
}

export default FullScreenLoading;