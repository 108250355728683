// Packages
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames'



// Assets
import './assets/styles.css';


const Button = props => {

    const { label, colour, link, onClick, size, loading } = props;

    const navigate = useNavigate();



    const classes = classnames(
        'Button',
        'Button--colour-' + colour,
        'Button--size-' + size,
        {
            'Button--loading': loading
        })


    return (
        <div onClick={() => {

            if ( link )
            {
                navigate(link);
            }
            else if ( onClick )
            {
                onClick();
            }

        }} className={classes}>

            {loading ? 'Please wait' : label}

        </div>
    );
}

Button.propTypes = {
    label: PropTypes.string.isRequired,
    colour: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    onClick: PropTypes.func
}

Button.defaultProps = {
    loading: false
}

export default Button;
