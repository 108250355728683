// Packages
import { useState, useEffect } from 'react';


// Components
import Container from 'components/Container';
import Loading from 'components/Loading';
import EventList from 'components/EventList';


// Other
import Api from 'other/Api';




const EventListScreen = () => {

    // State
    const [ events, setEvents ] = useState();
    const [ eventsLoading, setEventsLoading ] = useState(true);

    useEffect(() => {

        Api.events.list({
            success: (response) => {
                setEvents(response.data);
            },
            error: (error) => {
                alert('Error: ' + error.message);
            },
            after: () => {
               setEventsLoading(false);
            }
        })

    }, []);


    if ( eventsLoading )
    {
        return (
            <Container width="narrow">
                <Loading align="center" message="Loading available events"/>
            </Container>
        )
    }


	return (
		<>
            <Container width="narrow">
			 <EventList items={events}/>
            </Container>
        </>
	)
}

export default EventListScreen
