// Libraries
import PropTypes from 'prop-types';


// Assets
import './assets/styles.css';


const Loading = props => {

    // Props
    const { message, style, size, align } = props;


    return (
        <div className={`Loading Loading--align-${align} Loading--size-${size}`} style={style}>

            <div className="Loading__spinner"/>

            { message &&
                <div className="Loading__message">{message}</div>
            }

        </div>
    );
}


// Prop Types
Loading.propTypes = {
    message: PropTypes.string.isRequired,
    style: PropTypes.object.isRequired,
    align: PropTypes.string.isRequired
}


// Default Props
Loading.defaultProps = {
    message: '',
    style: {},
    align: 'left'
}


export default Loading;
