// Packages
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import nl2br from 'react-nl2br'


// Components
import Container from 'components/Container';


// Assets
import './assets/styles.css';


const IntroductionSection = props => {

    // Props
    const { text, signoff } = props;

    const {
        user
    } = useAuth0();

    return (
        <section id="introduction" className="IntroductionSection">

            <Container>

                <div className="IntroductionSection__body">
                   <div className="IntroductionSection__title">Dear {user.given_name}!</div>
                   <div className="IntroductionSection__text"><p>{nl2br(text)}</p></div>
                   <div className="IntroductionSection__signoff">{signoff}</div>
                </div>

            </Container>

        </section>
    );
}


IntroductionSection.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    signoff: PropTypes.string.isRequired
}

export default IntroductionSection;
