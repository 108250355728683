// Components
import PropTypes from 'prop-types'
import Container from 'components/Container'
import SectionTitle from 'components/SectionTitle'
import FaqItem from './Item';


// Assets
import './assets/styles.css'


const FaqsSection = props => {

    // Props
    const { items } = props;


    return (
        <div id="faqs" className="FaqsSection">
            <Container>
                <SectionTitle icon="faqs" title="Frequently Asked Questions"/>

                <div className="FaqsSection__items">

                        {items.map((item) => {

                            return (
                                <div key={item.id} className="FaqsSection__items__item">
                                    <FaqItem key={item.id} {...item}/>
                                </div>
                            )

                        })}

                </div>

                <p className="FaqsSection__chat">If you have a question then please use the chat button at the bottom of the screen.</p>



            </Container>
        </div>
    )
}

FaqsSection.propTypes = {
    items: PropTypes.array.isRequired
}

export default FaqsSection
