// Libraries
import { Routes, Route } from 'react-router-dom';


// Screens
import ListScreen from 'screens/Event/List';
import ViewScreen from 'screens/Event/View';
import BasketScreen from 'screens/Event/Basket';


// Layouts
import Layout from 'layouts/Event';


const EventRoutes = () =>
{
	return (
		<Layout>
			<Routes>
                <Route exact path="/" element={<ListScreen/>}/>
                <Route exact path="/:eventId" element={<ViewScreen/>}/>
                <Route exact path="/:eventId/basket" element={<BasketScreen/>}/>
			</Routes>
		</Layout>
	)
}


export default EventRoutes
