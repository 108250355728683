// Packages
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';


// Components
import TicketQuantityControl from 'components/TicketQuantityControl';


// Assets
import './assets/styles.css';


const TicketTypeSelector = props => {

    // Props
    const { items, onChange } = props;


    // State
    const [ ticketSelection, setTicketSelection] = useState({});


    useEffect(() => {



    }, [ticketSelection]);




    return (
        <div className="TicketTypeSelector">

            {items.map((item) => {

                return (

                    <div key={item.id} className="TicketTypeSelector__item">
                        <div className="TicketTypeSelector__item__name">{item.name}</div>
                        <div className="TicketTypeSelector__item__price">£ {item.price}</div>
                        <div className="TicketTypeSelector__item__quantity">
                            <TicketQuantityControl onChange={(quantity) => {

                                let currentSelection = ticketSelection;
                                currentSelection[item.id] = {
                                    quantity: quantity,
                                    event_id: item.event_id,
                                    ticket_type_id: item.id,
                                    ticketType: item
                                }
                                setTicketSelection(currentSelection);

                                let tickets = [];

                                for ( var name in currentSelection )
                                {
                                    tickets.push(ticketSelection[name]);
                                }

                                onChange(tickets);
                            }} max={item.limit_per_order}/>
                            { item.limit_per_order &&
                                <div className="TicketTypeSelector__item__quantity__limit">
                                    Max: {item.limit_per_order}
                                </div>
                            }
                        </div>
                    </div>
                )

            })}

        </div>
    );
}

TicketTypeSelector.propTypes = {
    onChange: PropTypes.func.isRequired
}


export default TicketTypeSelector;
