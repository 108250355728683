
// Components
import Header from 'components/Header'
import Footer from 'components/Footer'


const DefaultLayout = props => {

    return (
        <div className="DefaultLayout">
            <div className="DefaultLayout__header">
                <Header/>
            </div>
            <div className="DefaultLayout__body">
                {props.children}
            </div>
            <div className="DefaultLayout__footer">
                <Footer/>
            </div>
        </div>
    )
}


export default DefaultLayout
