// Packages
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'


// Components
import Container from 'components/Container'
import SectionTitle from 'components/SectionTitle'
import Button from 'components/Button'
import Loading from 'components/Loading'
import BasketExpiryTimer from 'components/BasketExpiryTimer'
import AttendeeForm from 'components/AttendeeForm'
import Error from 'components/Error'


// Other
import Api from 'other/Api'


// Assets
import './assets/styles.css'


const AttendeesScreen = () => {

    const { checkoutId } = useParams()


    // State
    const [ checkout, setCheckout ] = useState()
    const [ checkoutLoading, setCheckoutLoading ] = useState(true)


    // State
    const [ ticketAttributes, setTicketAttributes ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState()

    const navigate = useNavigate()



    useEffect(() => {

        Api.checkouts.get(checkoutId, {
            success: (response) => {
                setCheckout(response.data);

                // build ticket attributes data
                let attributes = []

                for ( var i =0; i<response.data.tickets.length; i++ )
                {
                    attributes.push({
                        attendee_name: response.data.tickets[i].attendee_name,
                        dietary_requirements: response.data.tickets[i].dietary_requirements,
                        allergies: response.data.tickets[i].allergies,
                        accessibility_requirements: response.data.tickets[i].accessibility_requirements
                    })
                }

                setTicketAttributes(attributes)

            },
            error: (error) => {
                alert('error');
            },
            after: () => {
                setCheckoutLoading(false)
            }
        })

    }, [checkoutId])


    const saveTicketNames = () => {

        let tickets = [];

        for ( var i=0; i<checkout.tickets.length; i++ )
        {
            if ( ! ticketAttributes[i].dietary_requirements ||
                  ticketAttributes[i].dietary_requirements.length === 0 ||
                  ! ticketAttributes[i].attendee_name ||
                  ticketAttributes[i].attendee_name === '' )
            {
                setErrorMessage('Please ensure you\'ve completed the name and dietary requirements of all attendees')
                return false;
            }

            tickets.push({...checkout.tickets[i], ...ticketAttributes[i] })
        }

        Api.checkouts.update(checkoutId, {
            data: {
               tickets
            },
            before: () => {
                setLoading(true);
            },
            successRedirect: (response) => {
                navigate('/checkout/' + checkoutId + '/ambassador')
            },
            error: (error) => {
                alert("error!")
            },
            after: () => {
                setLoading(false);
            },
        })

    }




    if ( checkoutLoading )
    {
        return (
            <Container width="narrow">
                <Loading align="center" message="Loading basket"/>
            </Container>
        );
    }



	return (
		<>



            <Container width="narrow">

                <BasketExpiryTimer expiry={checkout.expires_at}/>

                <SectionTitle title="Attendee Information"/>

                {checkout.tickets.map((ticket, index) => {

                    return (
                        <div key={index} className="CheckoutName__ticket">
                            <b>{ticket.ticket_type.name}</b>

                            <AttendeeForm
                                fixedName={ticket.attendee_name_fixed ?? false}
                                defaultDiet={ticket.dietary_requirements}
                                defaultName={ticket.attendee_name}
                                defaultAllergies={ticket.allergies}
                                defaultAccessibility={ticket.accessibility_requirements}
                                onChange={(values) => {

                                let newAttributes = [...ticketAttributes]
                                newAttributes[index] = values
                                setTicketAttributes(newAttributes)

                            }}/>

                        </div>
                    )

                })}

                { errorMessage &&
                    <Error message={errorMessage}/>
                }

                <div style={{textAlign: 'center', marginTop: '50px'}}><Button onClick={saveTicketNames} colour="red" label="Continue >" loading={loading}/></div>

            </Container>

        </>
	)
}

export default AttendeesScreen
