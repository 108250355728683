// Packages
import dayjs from 'dayjs';


// Components
import Container from 'components/Container';


// Assets
import './assets/styles.css';
import logo from './assets/logo.svg';


const Footer = () => {
    return (
        <footer className="Footer">

            <Container>

                <div className="Footer__left">
                    <img src={logo} alt="Tropic Skincare" className="Footer__logo"/>
                </div>

                <div className="Footer__right">
                    &copy; {dayjs().format('YYYY')} Tropic Skincare Ltd
                </div>

            </Container>

        </footer>
    )
}

export default Footer
