// Components
import PropTypes from 'prop-types'
import Container from 'components/Container'


// Assets
import './assets/styles.css'


const ImageHeaderSection = props => {

    // Props
    const { imageUrl } = props


    const style = {
        backgroundImage: "url('" + imageUrl + "')"
    }



    return (
        <section className="ImageHeaderSection" style={style}>

            <Container>



            </Container>

        </section>
    )
}

ImageHeaderSection.propTypes = {
    imageUrl: PropTypes.string.isRequired
}

export default ImageHeaderSection
