

// Assets
import './assets/styles.css';

const Overlay = props => {

    return (
        <div className="Overlay">
            {props.children}
        </div>
    );
}

export default Overlay;
