// Packages
import PropTypes from 'prop-types';


const UserInitials = props => {

    // Props
    const { firstname, lastname } = props;


    const getInitials = () => {

        let initials = '';

        if ( firstname ) {
            initials += firstname.substring(0, 1);
        }

        if ( lastname ) {
            initials += lastname.substring(0, 1);
        }

        return initials.trim().toUpperCase();
    }


    return (
        <>
        {getInitials()}
        </>
    );

}


// Prop types
UserInitials.propTypes = {
    firstname: PropTypes.string,
    lastname: PropTypes.string
};


export default UserInitials;