// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';

// Assets
import './assets/styles.css';


const EventNavigation = props => {

    // Props
    const { items } = props;


    return (
        <div className="EventNavigation">
            <ul className="EventNavigation__list">

                {items.map((item) => {

                    return (
                        <li key={item.label} className="EventNavigation__list__item">

                            <Link to={item.anchor} smooth={true} className="EventNavigation__list__item__link">{item.label}</Link>

                        </li>
                    )

                })}

            </ul>
        </div>
    )
}


EventNavigation.propTypes = {
    items: PropTypes.array.isRequired
}

export default EventNavigation;
