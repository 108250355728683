/*

Main configuration file for this app

React only allows two environments natively (dev and prod), so
we have to manage our own env config :(

*/


/*
General Configuration
*/
const generalConfig = {
    STAGE: process.env.REACT_APP_STAGE,
    AUTH0_SCOPES: "openid profile email"
}


/*
Environment-specific Configuration
*/
const environmentConfig = {

    /*
    Production Environment Configuration
    */
    production: {
        API_URL: "https://api.events.tropicskincare.com",
        AUTH0_DOMAIN: "id.tropicskincare.com",
        AUTH0_CLIENT_ID: "CSt52khZZesSAqoqp4shL1NVSpGiwvkA",

        STRIPE_PUBLIC_KEY: "pk_test_51LGLERLBEsKHG6hM0JiGhuOsGh2n7vuvKrgu6YpKe1HMgruO1GvdOHqvUl81B44nCDxqQLzSibLsbBBIClObhcBJ00y2J9schU"
    },


    /*
    Staging Environment Configuration
    */
    staging: {
        API_URL: "https://api.events.stage.tropicskincare.com",
        AUTH0_DOMAIN: "id.stage.tropicskincare.com",
        AUTH0_CLIENT_ID: "O6RxV1T6vcg3S1KLRlHB47yRnObxeIrd",

        STRIPE_PUBLIC_KEY: "pk_test_51LGLERLBEsKHG6hM0JiGhuOsGh2n7vuvKrgu6YpKe1HMgruO1GvdOHqvUl81B44nCDxqQLzSibLsbBBIClObhcBJ00y2J9schU"
    },


    /*
    Development Environment Configuration
    */
    development: {
        API_URL: "https://api.events.dev.tropicskincare.com",
        AUTH0_DOMAIN: "id.dev.tropicskincare.com",
        AUTH0_CLIENT_ID: "f6ArqxMzBCQsGd8Bqj2wRvSUCCfFu4rD",

        STRIPE_PUBLIC_KEY: "pk_test_51LGLERLBEsKHG6hM0JiGhuOsGh2n7vuvKrgu6YpKe1HMgruO1GvdOHqvUl81B44nCDxqQLzSibLsbBBIClObhcBJ00y2J9schU"
    }

}

const config = {
    ...generalConfig,
    ...environmentConfig[process.env.REACT_APP_STAGE]
}

export default config
