// Packages
import { useEffect, useState } from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'


// Components
import Container from 'components/Container'
import SectionTitle from 'components/SectionTitle'
import Loading from 'components/Loading'


// Other
import Api from 'other/Api'



const ProcessScreen = () => {

    // URL Params
    const { checkoutId } = useParams()


    // State
    const [ checkoutLoading, setCheckoutLoading ] = useState(true)
    const [ searchParams ] = useSearchParams()


    // Grab payment info from URL
    const paymentIntentId = searchParams.get('payment_intent')

    const navigate = useNavigate()





    useEffect(() => {

        Api.checkouts.approve(checkoutId, {
            data: {
                payment_intent_id: paymentIntentId
            },
            success: (response) => {
                navigate(`/checkout/complete`)
            },
            error: (error) => {
                console.log('error', error)
                alert('error');
            },
            after: () => {
                setCheckoutLoading(false)
            }
        })


    }, [checkoutId, paymentIntentId, navigate])


    if ( checkoutLoading )
    {
        return (<Loading/>)
    }


    return (
		<>

            <Container width="narrow">

                <SectionTitle title="Processing Your Order"/>
                
                <Loading size="large" align="center" style={{marginTop: '50px'}}/>

            </Container>

        </>
	)
}

export default ProcessScreen
