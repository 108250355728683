// Packages
import PropTypes from 'prop-types'


// Assets
import './assets/styles.css'



const Error = props => {

    // Props
    const { message } = props

    return (
        <div className="Error">
            {message}
        </div>
    )
}

Error.propTypes = {
    message: PropTypes.string.isRequired
}

export default Error
