
// Routes
import MainRoutes from 'routes/main';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from "react-router-dom";


// Other
import Auth from './other/Auth';
import Config from './other/Config';


// Assets
import './assets/styles.css';


const App = () =>
{

    const navigate = useNavigate();

    const onRedirectCallback = (appState) => {

        navigate(appState?.returnTo || window.location.pathname);
    };


  return (
    <Auth0Provider
            domain={Config.AUTH0_DOMAIN}
            clientId={Config.AUTH0_CLIENT_ID}
            redirectUri={window.location.origin}
            scope={Config.AUTH0_SCOPES}
            audience="https://api.events.tropicskincare.com"
            onRedirectCallback={onRedirectCallback}
        >
        <Auth>
            <MainRoutes/>
        </Auth>
    </Auth0Provider>
  )
}

export default App
