// Packages
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'


// Assets
import './assets/styles.css'


const CheckboxList = props => {

    // Props
    const { values, items, onChange, showOther } = props


    // State
    const [selected, setSelected] = useState([])
    const [otherSelected, setOtherSelected] = useState(false)
    const [other, setOther] = useState()

    useEffect(() => {
        setSelected(values)

        for ( var i = 0; i < values.length; i++ )
        {
            if ( items.indexOf(values[i]) === -1 )
            {
                setOtherSelected(true);
                setOther(values[i])
                //alert('not found: ' + values[i])
            }
        }

    }, [values, items])


    const addOption = (option) => {
        let newSelected = [...selected]
        newSelected.push(option)
        setSelected(newSelected)
        onChange(newSelected)
    }


    const removeOption = (option) => {
        let newSelected = []

        for ( var i = 0; i<selected.length; i++ )
        {
            if ( selected[i] !== option )
            {
                newSelected.push(selected[i])
            }
        }

        setSelected(newSelected)
        onChange(newSelected)
    }

    const isSelected = (option) => {
        for ( var i = 0; i<selected.length; i++ )
        {
            if ( selected[i] === option )
            {
                return true
            }
        }

        return false
    }


    return (
        <div className="CheckboxList">

            {items.map((item) => {
                return (
                    <label key={item} className="CheckboxList__item">
                        <div className="CheckboxList__item__checkbox">
                            <input onChange={(e) => {

                                if ( e.target.checked )
                                {
                                    addOption(item)
                                }
                                else
                                {
                                    removeOption(item)
                                }

                            }} checked={isSelected(item)} type="checkbox"/>
                        </div>
                        <div className="CheckboxList__item__label">
                            {item}
                        </div>
                    </label>
                )
            })}

            { showOther &&
                <>
                <label key="other" className="CheckboxList__item">
                    <div className="CheckboxList__item__checkbox">
                        <input onChange={(e) => {

                            setOtherSelected(e.target.checked)

                        }} checked={otherSelected} type="checkbox"/>
                    </div>
                    <div className="CheckboxList__item__label">
                        Other
                    </div>
                </label>

                { otherSelected &&
                    <div className="form__field">
                    <label className="form__field__label">Other Dietary Requirements</label>
                        <textarea onChange={(e) => {
                            setOther(e.target.value)
                        }} onBlur={(e) => {
                            addOption(other)
                        }} value={other} className="form__field__input" placeholder="Enter dietary information"/>
                    </div>
                }

                </>
            }

        </div>
    )
}

CheckboxList.propTypes = {
    onChange: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    values: PropTypes.array,
    showOther: PropTypes.bool
}

CheckboxList.defaultProps = {
    showOther: false
}

export default CheckboxList
