// Packages
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';


// Components
import Container from 'components/Container'
import SectionTitle from 'components/SectionTitle'


// Assets
import './assets/styles.css'


const AccountHomeScreen = () => {

    /*
    Import variables from the Auth0 context
    */
    const {
        user
    } = useAuth0();


    const ITEMS = [
        { label: 'View your orders', url: '/account/orders' }
    ]

	return (
		<>
            <Container width="narrow">
                <SectionTitle title={`${user.given_name}'s Account`}/>

                <br/><br/>

                {ITEMS.map((item) => {
                    return (
                        <Link to={item.url} key={item.label} className="AccountHomeScreen__item">
                            {item.label}
                        </Link>
                    )
                })}
            </Container>
        </>
	)
}

export default AccountHomeScreen
