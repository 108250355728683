// Components
import PropTypes from 'prop-types'
import Container from 'components/Container'
import SectionTitle from 'components/SectionTitle'
import Button from 'components/Button';

// Assets
import './assets/styles.css'


const TicketOptionsSection = props => {

    // Props
    const { items } = props;


    return (
        <div id="tickets" className="TicketOptionsSection">
            <Container>
                <SectionTitle icon="tickets" colour="white" title="Ticket Options"/>

                <div className="TicketOptionsSection__items">

                {items.map((item) => {

                    if ( item.enabled === false )
                    {
                        return (<></>)
                    }

                    return (
                        <div key={item.id} className="TicketOptionsSection__items__item">

                            <div className="TicketOptionsSection__items__item__name">{item.name}</div>
                            <div className="TicketOptionsSection__items__item__price">&pound;{item.price}</div>

                            <div className="TicketOptionsSection__items__item__info">(Limit of {item.limit_per_order} per order)</div>

                            <div className="TicketOptionsSection__items__item__button">
                                <Button link={`/events/${item.event_id}/basket`} colour="darkblue" label="Buy Tickets >"/>
                            </div>

                        </div>
                    )

                })}

                </div>


            </Container>
        </div>
    )
}

TicketOptionsSection.propTypes = {
    items: PropTypes.array.isRequired
}

export default TicketOptionsSection
