// Packages
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'


// Components
import Loading from 'components/Loading'
import Button from 'components/Button'
import IntroductionSection from 'components/Sections/Introduction'
import ActionBarSection from 'components/Sections/ActionBar'
import TicketOptionsSection from 'components/Sections/TicketOptions'
import AccommodationSection from 'components/Sections/Accommodation'
import VenueSection from 'components/Sections/Venue'
import FaqsSection from 'components/Sections/Faqs'
import Container from 'components/Container'
import ImageHeader from 'components/Sections/ImageHeader'


// Other
import Api from 'other/Api'


// Assets
import './assets/styles.css'


const EventScreen = () => {

    // URL Parameters
    const { eventId } = useParams()


    // State
    const [event, setEvent] = useState()
    const [eventLoading, setEventLoading] = useState(true)

    const [ticketTypes, setTicketTypes] = useState()
    const [ticketTypesLoading, setTicketTypesLoading] = useState(true)

    const [hotels, setHotels] = useState([])
    const [hotelsLoading, setHotelsLoading] = useState(true)

    const [faqs, setFaqs] = useState()
    const [faqsLoading, setFaqsLoading] = useState(true)


    useEffect(() => {

        Api.events.get(eventId, {
            params: {
                with: ['venue']
            },
            success: (response) => {
                setEvent(response.data);
            },
            error: (error) => {
                alert('Error loading event: ' + error);
            },
            after: () => {
                setEventLoading(false);
            }
        });

        Api.ticketTypes.list({
            params: {
                eventId
            },
            success: (response) => {
                setTicketTypes(response.data);
            },
            error: (error) => {
                alert('Error loading ticket types: ' + error);
            },
            after: () => {
                setTicketTypesLoading(false);
            }
        });

        Api.hotels.list({
            params: {
                eventId
            },
            success: (response) => {
                setHotels(response.data);
            },
            error: (error) => {
                alert('Error loading hotels: ' + error);
            },
            after: () => {
                setHotelsLoading(false);
            }
        });

        Api.faqs.list({
            params: {
                eventId
            },
            success: (response) => {
                setFaqs(response.data);
            },
            error: (error) => {
                alert('Error loading faqs: ' + error);
            },
            after: () => {
                setFaqsLoading(false);
            }
        });

    }, [eventId]);



    if ( eventLoading )
    {
        return (
            <Container width="narrow">
                <Loading align="center" message="Loading event"/>
            </Container>
        );
    }

	return (
		<>
            <ImageHeader imageUrl={event.header_image_url}/>

            {/*<HeaderSection
                title={event.name}
                date={event.date}
                time={event.time.substring(0, 5)}
                venue={event.venue.name}
            />*/}

            <ActionBarSection>
                <Button colour="darkblue" link={`/events/${event.id}/basket`} label="Buy Tickets >"/>
                <Button colour="darkblue" label="Add to your calendar"/>
            </ActionBarSection>

            <IntroductionSection
                title="Dear Helena,"
                text={event.introduction}
                signoff={event.introduction_signoff}
            />

            { ticketTypes && ! ticketTypesLoading &&
                <TicketOptionsSection items={ticketTypes}/>
            }

            { ! hotelsLoading && hotels.length > 0 &&
                <AccommodationSection items={hotels}/>
            }


            <VenueSection address={event.venue.address}/>

            { faqs && ! faqsLoading &&
                <FaqsSection items={faqs}/>
            }



        </>
	)
}

export default EventScreen
