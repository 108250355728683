// Packages
import PropTypes from 'prop-types';


// Assets
import './assets/styles.css';


const FullScreenError = props => {

    // Props
    const { type, title, message } = props;
    

    return (
        <div className="FullScreenError">
            <div className="FullScreenError__body">
                <div className={`FullScreenError__icon FullScreenError__icon--${type}`}></div>

                <div className="FullScreenError__title">{title}</div>

                { message &&
                    <div className="FullScreenError__message">{message}</div>
                }
            </div>
        </div>
    );
    
}


// Prop types
FullScreenError.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    type: PropTypes.oneOf(['access-denied'])
};


export default FullScreenError;