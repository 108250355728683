// Packages
import PropTypes from 'prop-types';
import ClassNames from 'classnames';


// Assets
import './assets/styles.css';


const SectionTitle = props => {

    // Props
    const { title, colour, icon } = props;


    const classes = ClassNames(
        'SectionTitle',
        'SectionTitle--colour-' + colour,
        'SectionTitle--icon-' + icon,
        {
            'SectionTitle--icon': icon !== ''
        }
    )


    return (
        <div className={classes}>
            {title}
        </div>
    );
}


SectionTitle.propTypes = {
    title: PropTypes.string.isRequired,
    colour: PropTypes.string,
    icon: PropTypes.string
}


SectionTitle.defaultProps = {
    color: '',
    icon: ''
}

export default SectionTitle;
