// Packages
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react'


// Components
import Container from 'components/Container';
import Loading from 'components/Loading';
import Button from 'components/Button';
import TicketTypeSelector from 'components/TicketTypeSelector';
import SectionTitle from 'components/SectionTitle'
import Error from 'components/Error'


// Other
import Api from 'other/Api';


// Assets
import './assets/styles.css';


const EventScreen = () => {

    // URL Parameters
    const { eventId } = useParams();


    /*
    Import variables from the Auth0 context
    */
    const {
        user
    } = useAuth0();

    const [ticketTypes, setTicketTypes] = useState()
    const [ticketTypesLoading, setTicketTypesLoading] = useState(true)

    const [selectedTickets, setSelectedTickets] = useState({})
    const [total, setTotal] = useState(0)

    const [activity, setActivity] = useState(false)
    const [error, setError] = useState()


    const navigate = useNavigate();


    useEffect(() => {

        Api.ticketTypes.list({
            params: {
                eventId
            },
            success: (response) => {
                setTicketTypes(response.data);
            },
            error: (error) => {
                alert('Error loading ticket types: ' + error);
            },
            after: () => {
                setTicketTypesLoading(false);
            }
        });

    }, [eventId]);


    useEffect(() => {

        let newTotal = 0;

        for ( var ticketId in selectedTickets )
        {
            newTotal += selectedTickets[ticketId].ticketType.price * selectedTickets[ticketId].quantity;
        }

        setTotal(newTotal)

    }, [selectedTickets]);


    const addToBasket = async () => {

        setError()


        if ( Object.keys(selectedTickets).length === 0 )
        {
            setError('Please select the tickets to purchase')
            return
        }

        await Api.checkouts.create({
            data: {
                firstname: user.given_name,
                lastname: user.family_name,
                email: user.email,
                tickets: selectedTickets
            },
            before: () => {
                setActivity(true)
            },
            after: () => {
                setActivity(false)
            },
            successRedirect: (response) => {
                navigate('/checkout/' + response.data.id)
            },
            error: () => {
                alert('error');
            }

        })


    }



	return (
		<>



            <Container width="narrow">

                <SectionTitle title="Select Your Tickets"/>

                { ticketTypesLoading &&
                    <Container width="narrow">
                        <Loading align="center" message="Loading ticket types"/>
                    </Container>
                }

                { ! ticketTypesLoading &&
                    <TicketTypeSelector onChange={(tickets) => {
                        setSelectedTickets(tickets);
                    }} items={ticketTypes}/>
                }

                <div style={{textAlign: 'center', margin: '20px 0'}}>
                    <b>Total Price: £ {total}</b>
                </div>

                { error &&
                    <Error message={error}/>
                }

                <Button onClick={() => {
                    addToBasket();
                }} colour="red" loading={activity} label="Continue >"/>

            </Container>

        </>
	)
}

export default EventScreen
