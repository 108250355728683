// Packages
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';


// Components
import { userHasRole } from 'other/Utilities';
import FullScreenLoading from 'components/FullScreenLoading';
import FullScreenError from 'components/FullScreenError';



const Auth = props => {

    /*
    Import variables/methods from the Auth0 context
    */
    const {
        isLoading,
        isAuthenticated,
        error,
        loginWithRedirect,
        user,
        getAccessTokenSilently
    } = useAuth0();


    // State
    const [ accessToken, setAccessToken ] = useState();


    useEffect(() => {

        const getAcccessToken = async () => {

            const accessToken = await getAccessTokenSilently();
            sessionStorage.setItem('access-token', accessToken);
            setAccessToken(accessToken);
        }

        if ( isAuthenticated )
        {
            getAcccessToken();
        }

    }, [isAuthenticated, getAccessTokenSilently])


    /*
    Auth0 is processing - show full-screen loading spinner
    */
    if ( isLoading )
    {
        return <FullScreenLoading/>
    }


    /*
    An error has occurred with the Auth0 communication
    */
    if ( error )
    {
        return <FullScreenError title="Authentication Error" type="access-denied" message={error.message}/>
    }


    if ( isAuthenticated )
    {
        /*
        The user is authenticated and has a valid Auth0 token
        */

        // Check that the user has the 'staff' or 'admin' role
        if ( ! userHasRole(['staff', 'admin'], user['https://id.tropicskincare.com/roles']) )
        {
            return <FullScreenError title="Access Denied" type="access-denied" message="You don't have access to the Tropic Admin Portal"/>
        }

        if ( accessToken )
        {
            return props.children;
        }
        else
        {
            return (<> Getting access token</>);
        }

    }
    else
    {
        /*
        The user needs to login, redirect to Auth0
        */
        const returnTo = window.location.pathname + window.location.search + window.location.hash;
        loginWithRedirect({ appState: { returnTo: returnTo } });
    }
}

export default Auth;
