// Packages
import { useEffect, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'



// Components
import Container from 'components/Container'
import SectionTitle from 'components/SectionTitle'
import Loading from 'components/Loading'


// Other
import Api from 'other/Api'




const UserOrderViewScreen = () => {


    const { orderId } = useParams()


    // State
    const [ order, setOrder ] = useState()
    const [ orderLoading, setOrderLoading ] = useState(true)


    const loadOrder = useCallback(() => {

        Api.user.getOrder(orderId, {
            success: (response) => {
                setOrder(response.data)
            },
            error: (error) => {
                alert('error');
            },
            after: () => {
                setOrderLoading(false)
            }
        })
    }, [orderId])



    useEffect(() => {

        loadOrder()

    }, [loadOrder])




    if ( orderLoading )
    {
        return (
            <Container width="narrow">
                <Loading align="center" message="Loading order"/>
            </Container>
        );
    }









	return (
		<>



            <Container width="narrow">

                <SectionTitle title="View Order"/>


                <div className="SummaryScreen__ambassador">
                    <div className="SummaryScreen__ambassador__header">
                        Ambassador
                    </div>
                    <div className="SummaryScreen__ambassador__body">
                        <b>#{order.ambassador.id}<br/></b>
                        <b>{order.ambassador.firstname} {order.ambassador.lastname}</b><br/>
                        <b>{order.ambassador.email}</b><br/>
                    </div>
                </div>




                <div className="SummaryScreen__tickets">


                {order.tickets.map((ticket, index) => {

                    return (

                        <div key={index} className="SummaryScreen__tickets__item">
                            <div className="SummaryScreen__tickets__item__number">
                                #{index+1}
                            </div>
                            <div className="SummaryScreen__tickets__item__name">
                                <b>{ticket.event.name}</b><br/>
                                {ticket.ticket_type.name}<br/>
                                <div className="SummaryScreen__tickets__item__attendeeName">{ticket.attendee_name}</div>
                            </div>
                            <div className="SummaryScreen__tickets__item__price">
                                £ {Number(ticket.ticket_type.price).toFixed(2)}
                            </div>

                        </div>
                    )

                })}

                </div>


                <div className="SummaryScreen__summary">

                    <div>




                    </div>

                    <div className="SummaryScreen__summary__totals">

                        {order.coupon &&
                            <>
                                Sub Total: <b style={{marginLeft: '50px'}}>£ {Number(order.sub_total).toFixed(2)}</b><br/>
                                Discount ({order.coupon.code}): <b style={{marginLeft: '50px'}}>- £ {Number(order.discount).toFixed(2)}</b><br/>
                            </>
                        }

                        Total: <b style={{marginLeft: '50px'}}>£ {Number(order.total).toFixed(2)}</b>
                    </div>

                </div>


            </Container>

        </>
	)
}

export default UserOrderViewScreen
