// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'


// Assets
import './assets/styles.css'


const EventList = props => {

    // Props
    const { items } = props;


    return (
        <div className="EventList">

            {items.map((item) => {

                const style = {
                    backgroundImage: "url('" + item.header_image_url + "')"
                }

                return (
                    <Link key={item.id} to={`/events/${item.id}`}className="EventList__item">
                        <div className="EventList__item__header" style={style} >

                        </div>

                        <div className="EventList__item__body">
                            <div className="EventList__item__name">{item.name}</div>
                            <div className="EventList__item__date">{dayjs(item.date).format('D MMMM YYYY')}</div>
                        </div>

                    </Link>
                )

            })}

        </div>
    );
}

EventList.propTypes = {
    items: PropTypes.array.isRequired
}

export default EventList;
