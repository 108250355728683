

// Components
import Container from 'components/Container';


// Assets
import './assets/styles.css';


const ActionBarSection = props => {

    return (
        <div className="ActionBarSection">
            <Container>
                {props.children}
            </Container>
        </div>
    )
}

export default ActionBarSection
