// Packages
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'


// Components
import Container from 'components/Container'
import SectionTitle from 'components/SectionTitle'
import PaymentForm from 'components/PaymentForm'
import Loading from 'components/Loading'
import BasketExpiryTimer from 'components/BasketExpiryTimer'


// Other
import Api from 'other/Api'
import Config from 'other/Config'


const stripePromise = loadStripe(Config.STRIPE_PUBLIC_KEY)


const PaymentScreen = () => {

    const { checkoutId } = useParams()


    // State
    const [ checkout, setCheckout ] = useState()

    const [ paymentIntent, setPaymentIntent ] = useState()
    const [ paymentIntentLoading, setPaymentIntentLoading] = useState(true)


    useEffect(() => {

        if ( ! paymentIntent )
        {
            Api.checkouts.getPaymentIntent(checkoutId, {
                success: (response) => {
                    setCheckout(response.data)
                    setPaymentIntent(response.data.stripe_payment_intent);
                    setPaymentIntentLoading(false)
                },
                error: (error) => {
                    if ( error.error === 'idempotency_key_in_use' )
                    {
                        // Ignore this
                        // It's a duplicate request
                        // m
                    }
                    else
                    {
                        alert('error');
                    }
                }
            })
        }

    }, [checkoutId, paymentIntent])


    if ( paymentIntentLoading )
    {
        return (<Loading/>)
    }

    let redirectUrl = window.location.protocol + '//' + window.location.hostname

    if ( window.location.port !== 80 && window.location.port !== 443 )
    {
        redirectUrl += ':' + window.location.port
    }

    redirectUrl += '/checkout/' + checkoutId + '/process'


    return (
		<>

            <Container width="narrow">

                <BasketExpiryTimer expiry={checkout.expires_at}/>

                <SectionTitle title="Payment"/>
                <br/><br/>

                <Elements stripe={stripePromise} options={{
                    clientSecret: paymentIntent.client_secret
                }}>
                    <PaymentForm returnUrl={redirectUrl}/>
                </Elements>

            </Container>

        </>
	)
}

export default PaymentScreen
