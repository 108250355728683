// Packages
import { useState } from 'react'
import nl2br from 'react-nl2br'


// Components
import Modal from 'components/Modal'


// Assets
import './assets/styles.css';


const AccommodationItem = props => {

    // Props
    const { id, name, miles_to_venue, address, url, details, photo_url } = props;


    // State
    const [modal, setModal] = useState(false);


    const style = {}

    if ( photo_url )
    {
        style.backgroundImage = "url('" + photo_url + "')"
    }


    return (
        <>
            <div onClick={() => {
                setModal(true)
            }} key={id} className="AccommodationSection__items__item">
                <div className="AccommodationSection__items__item__photo" style={style}/>
                <div className="AccommodationSection__items__item__name">{name}</div>
                <div className="AccommodationSection__items__item__miles">{miles_to_venue} miles from the venue</div>
                <div className="AccommodationSection__items__item__address">{address}</div>
            </div>

            { modal &&
                <Modal onClose={() => {

                    setModal(false)

                }}>
                    <div className="AccommodationSection__items__item__name">{name}</div>
                    <div className="AccommodationSection__items__item__miles">{miles_to_venue} miles from the venue</div>
                    <div className="AccommodationSection__items__item__address">{address}</div>
                    { url &&
                        <div className="AccommodationSection__items__item__link"><a href={url} target="_blank" rel="noreferrer">Click for booking link</a></div>
                    }
                    <div className="AccommodationSection__items__item__details">{nl2br(details)}</div>
                </Modal>
            }
            </>
    )
}

export default AccommodationItem
