// Packages
import { useState, useEffect } from 'react'
import dayjs from 'dayjs'


// Assets
import './assets/styles.css'


// Extend dayjs to use the 'Duration' feature
const duration = require('dayjs/plugin/duration')
dayjs.extend(duration)


const BasketExpiryTimer = props => {

    // Props
    const { expiry } = props;


    // State
    const [ minutes, setMinutes ] = useState();
    const [ seconds, setSeconds ] = useState();


    useEffect(() => {

        const runCountdown = () => {
            let now = dayjs();
            let eventDate = dayjs(expiry);

            let diff = dayjs.duration(eventDate.diff(now));

            setMinutes(diff.$d.minutes);
            setSeconds(diff.$d.seconds);

            setTimeout(() => {
                runCountdown();
            }, 1000)
        }

        runCountdown();

    }, [expiry])


    return (
        <div className="BasketExpiryTimer">
            <div className="BasketExpiryTimer__body">
                {minutes} mins and {seconds} secs to complete checkout
            </div>
        </div>
    )
}

export default BasketExpiryTimer
