// Packages
import PropTypes from 'prop-types'


// Components
import Overlay from 'components/Overlay';


// Assets
import './assets/styles.css';


const Modal = props => {

    // Props
    const { children, onClose } = props;


    return (
        <Overlay>
            <div className="Modal">
                <div className="Modal__body">{children}</div>
                <div className="Modal__footer">
                    <div onClick={onClose} className="Modal__close">Close Window</div>
                </div>
            </div>
        </Overlay>
    );  
}

Modal.propTypes = {
    onClose: PropTypes.func.isRequired
}

export default Modal;
